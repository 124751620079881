import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import Box from '@mui/material/Box'
import styles from './BBCIneligibleInventoryTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import ActiveToolbar from '../ActiveToolbar'
import Card from '../Common/Card'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import TableLoader from '../Common/TableLoader'
import {
  CUSTOM_RULES_FIELD,
  IClientCollateral,
  IInventoryIneligibleStats,
  IneligibleReasons,
} from '@common/interfaces/bbc'
import {
  debounceEventHandler,
  formatPrice,
  handleMultipleSelect,
  transformNegativeToZero,
} from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import FullscreenModal from '../Common/FullscreenModal'
import { IInventoryIneligibilityFieldAliases } from '@common/interfaces/client'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { BBC_INVENTORY_INELIGIBLE_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import { INELIGIBILE_INVENTORY_FIELDS_CONFIG } from '@common/constants/mappings'
import BBCIneligibleInventoryTableReason from '../BBCIneligibleInventoryTableReason'
import FilterContainer from '../Filters/FilterContainer'
import { ExpandAndMinimize } from '../Common/Icons/Icons'
import { ILoadingData } from '../../redux/types'
import { ExpandDetailIcon } from '../Common/Icons/Icons'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'

const filtersValidate = buildFiltersValidateSchema(BBC_INVENTORY_INELIGIBLE_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(BBC_INVENTORY_INELIGIBLE_LIST_FILTERS_CONFIG)

const FIELDS_LABELS = {
  ineligibleLocationsAndInTransit: IneligibleReasons.Locations,
  ineligibleSkus: IneligibleReasons.SKUsAndSeason,
  slowAgedOrExpired: IneligibleReasons.SlowMoving,
  invOtherMiscReserves: IneligibleReasons.OtherMiscReserves,
}

interface IProps {
  isLoadingBBC: boolean
  clientCollaterals: IClientCollateral[]
  inventoryIneligibleStatsData: ILoadingData<IInventoryIneligibleStats>
  listInventoryIneligibleStatsSummary: (id: string, data: object) => void
  aliases: IInventoryIneligibilityFieldAliases
  isFilesSaving: boolean
}

const BBCIneligibleInventoryTable = ({
  isLoadingBBC,
  clientCollaterals,
  inventoryIneligibleStatsData,
  listInventoryIneligibleStatsSummary,
  aliases,
  isFilesSaving,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const { isLoading, data: inventoryIneligibleStats } = inventoryIneligibleStatsData
  const [expanded, setExpanded] = useState([])

  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: 'value_0',
    direction: 'DESC',
  })
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])

  const [isModalShown, setIsModalShown] = useState(false)

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleOrderChange = useCallback((orderField: string) => {
    setOrderBy((order) => ({
      field: orderField,
      direction: order.field === orderField ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const handleFiltersChange = useCallback((values: any) => {
    setFilters(values)
  }, [])

  const filtersConfig = useMemo(
    () =>
      BBC_INVENTORY_INELIGIBLE_LIST_FILTERS_CONFIG.map((item) => {
        const newItem = item
        if (item?.field?.startsWith('value_')) {
          const index = Number(item.field.split('_')[1])
          const date = inventoryIneligibleStats?.dates[index]
          if (!date) {
            return null
          }
          newItem.title = index === 0 ? 'Current BBC' : date
        }
        return newItem
      }).filter(Boolean),
    [inventoryIneligibleStats],
  )

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        listInventoryIneligibleStatsSummary(id, data)
      }, 500),
    [listInventoryIneligibleStatsSummary, id],
  )

  useEffect(() => {
    debounceFilterList({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, debounceFilterList])

  const totalRow = useMemo(
    () =>
      inventoryIneligibleStats?.data
        ?.filter((_, index) => activeItems.includes(index))
        .map((row) => row.values)
        .reduce(
          (total, row) => {
            inventoryIneligibleStats?.dates.forEach((value: any, index: number) => {
              if (!total.values[index]) {
                total.values[index] = 0
              }
              total.values[index] += row[value] || 0
            })
            return total
          },
          { values: inventoryIneligibleStats?.dates.map(() => 0) },
        ),
    [inventoryIneligibleStats, activeItems],
  )

  const handleExpand = useCallback(
    (label: string) => {
      if (expanded.length === 0) {
        setIsModalShown(true)
      }
      setExpanded((values) =>
        values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
      )
    },
    [expanded],
  )

  const toggleModal = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const isInitialized = useMemo(() => !!inventoryIneligibleStats, [inventoryIneligibleStats])
  const visualizationsParams = useMemo(
    () => ({
      borrowingBaseId: id,
    }),
    [id],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.ineligibleInventoryTable,
    params: visualizationsParams,
    filtersConfig: filtersConfig,
    filters: filters,
    orderBy,
  })

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card id="ineligible-inventory" noHeaderMargin ref={wrapperRef}>
        <TableContainer
          className={styles.table}
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
          hasFooter
        >
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={filtersConfig}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                title={
                  <Box mr={2}>
                    <h2>Ineligible Inventory</h2>
                  </Box>
                }
                actions={
                  <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                    <ExpandAndMinimize action={toggleModal} isExpanded={isModalShown} />
                  </Box>
                }
              />
            )}
          />
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={filtersConfig}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
                isChildrenAtStart
              >
                <TableCell />
              </TableFiltersRow>
            </TableHead>
            <TableBody>
              {isFilesSaving || isLoadingBBC || isLoading ? (
                <TableLoader
                  columnsCount={(inventoryIneligibleStats?.dates.length || 1) + 2}
                  rowsCount={4}
                  height={24}
                />
              ) : (
                inventoryIneligibleStats?.data?.map(({ category, values, rows }, index) => {
                  const isExpanded = expanded.includes(category)

                  return (
                    <React.Fragment key={category}>
                      <TableRow
                        data-index={index}
                        className={cn('activableRow', styles.parentRow, {
                          activeRow: activeItems.includes(index),
                          currentActiveRow: activeItem === index,
                        })}
                        onClick={(event) => handleSelectRow(event, index)}
                      >
                        <TableCell className={genericSs.tableTextCenter}>
                          {Object.keys(values).length > 0 && (
                            <ExpandDetailIcon
                              onClick={() => handleExpand(category)}
                              isExpanded={isExpanded}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          className={cn(genericSs.tableTextLeft, genericSs.textCapitalize)}
                        >
                          {aliases[CUSTOM_RULES_FIELD[category]] ||
                            FIELDS_LABELS[category] ||
                            category}
                        </TableCell>
                        {inventoryIneligibleStats?.dates?.map((date) => {
                          const value = values[date]
                          return (
                            <TableCell key={date} className={genericSs.tableTextRight}>
                              $ {formatPrice(value || 0)}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                      {isExpanded && (
                        <TableRow>
                          <TableCell className={genericSs.nestedRowColumn} colSpan={3}>
                            <BBCIneligibleInventoryTableReason
                              inventoryIneligibleStatsReason={rows}
                              filters={filters}
                              isModalShown={isModalShown}
                              category={category}
                              dates={inventoryIneligibleStats?.dates}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                })
              )}
            </TableBody>
            {isFilesSaving || isLoadingBBC ? (
              <TableFooter>
                <TableLoader columnsCount={6} rowsCount={2} />
              </TableFooter>
            ) : (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className={genericSs.tableTextLeft}>
                    Total Ineligible
                  </TableCell>
                  {clientCollaterals?.map((clientCollateral) => (
                    <TableCell className={genericSs.tableTextRight} key={clientCollateral.id}>
                      ${' '}
                      {formatPrice(
                        clientCollateral[INELIGIBILE_INVENTORY_FIELDS_CONFIG.totalIneligible],
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className={genericSs.tableTextLeft}>
                    Total Eligible
                  </TableCell>
                  {clientCollaterals?.map((clientCollateral) => (
                    <TableCell className={genericSs.tableTextRight} key={clientCollateral.id}>
                      $&nbsp;
                      {formatPrice(
                        transformNegativeToZero(
                          clientCollateral[INELIGIBILE_INVENTORY_FIELDS_CONFIG.total] -
                            clientCollateral[INELIGIBILE_INVENTORY_FIELDS_CONFIG.totalIneligible],
                        ),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            )}
          </Table>

          <ActiveToolbar
            activeItems={activeItems}
            className={styles.activeToolbar}
            isFullscreen={isModalShown}
            containerRef={wrapperRef}
            resetActiveItems={resetActiveItems}
          >
            {totalRow?.values.map((value: number, index: number) => (
              <div key={index} className={genericSs.tableTextRight}>
                $ {formatPrice(value)}
              </div>
            ))}
          </ActiveToolbar>
        </TableContainer>
      </Card>
    </FullscreenModal>
  )
}

export default BBCIneligibleInventoryTable
