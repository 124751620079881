import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'
import styles from './ProspectReportingSummaryTermStructure.module.scss'

import { ILoadingData } from '../../redux/types'
import { IProspectTerms } from '@common/interfaces/prospects'
import { formatDate } from '../../helpers/helpers'
import { ROUTES } from '../../constants/routes'
import { LOAN_TYPES } from '@common/constants/client'
import {
  AR_SPECIFIC_TERMS_FIELDS,
  INVENTORY_SPECIFIC_TERMS_FIELDS,
  TERM_STRUCTURE_FIELDS,
  TERM_STRUCTURE_FIELDS_CONFIG,
} from '@common/constants/prospects'
import RenderedValue from '../RenderedValue'

interface IProps {
  termStructureSummaryInfo: ILoadingData<{ data: IProspectTerms }>
  showTermStructureSummaryInfo: (id: string) => void
}

const ProspectReportingSummaryTermStructureRow = ({
  field,
  data,
}: {
  field: string
  data: IProspectTerms
}) => {
  const fieldWithConfig = useMemo(
    () => TERM_STRUCTURE_FIELDS_CONFIG.find(({ value }) => value === field),
    [field],
  )

  if (
    !fieldWithConfig ||
    (AR_SPECIFIC_TERMS_FIELDS.includes(field) &&
      (!data?.loanType || data.loanType === LOAN_TYPES.inventory)) ||
    (INVENTORY_SPECIFIC_TERMS_FIELDS.includes(field) &&
      (!data?.loanType || data.loanType === LOAN_TYPES.ar)) ||
    ('qualifiedEquityRound' === field && !data?.covenantType)
  ) {
    return null
  }

  return (
    <div className={styles.dataRow}>
      <div className={styles.label}>{fieldWithConfig.label}</div>
      <div className={styles.value}>
        {data ? (
          <RenderedValue value={data[field]} type={fieldWithConfig.type} />
        ) : (
          <Skeleton width={100} height={15} />
        )}
      </div>
    </div>
  )
}

const ProspectReportingSummaryTermStructure = ({
  termStructureSummaryInfo,
  showTermStructureSummaryInfo,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { isLoading, termData } = useMemo(
    () => ({
      isLoading: termStructureSummaryInfo.isLoading,
      termData: termStructureSummaryInfo.data?.data,
    }),
    [termStructureSummaryInfo],
  )

  useEffect(() => {
    showTermStructureSummaryInfo(id)
  }, [showTermStructureSummaryInfo, id])

  if (termData && !termData.status) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Term Structure</div>

          <Link
            component={RouterLink}
            to={
              termData?.id
                ? generatePath(ROUTES.PROSPECT_EDIT_TERMS_PAGE, { id, termId: termData.id })
                : generatePath(ROUTES.PROSPECT_MANAGE_TERMS_PAGE, { id })
            }
            className={styles.editLink}
          >
            Edit
          </Link>
        </div>

        <div className={cn(styles.subTitle, genericSs.textCapitalize)}>
          {isLoading ? (
            <Skeleton className={styles.subTitleLoader} width={85} height={20} />
          ) : termData ? (
            `${termData.status} ${formatDate(termData.recordDate)}`
          ) : (
            '-'
          )}
        </div>
      </div>

      <div className={styles.data}>
        {TERM_STRUCTURE_FIELDS.map((data, index) => (
          <div key={index} className={styles.column}>
            {data.map(({ section, data }) => (
              <div key={section} className={styles.section}>
                <div className={styles.sectionTitle}>{section}</div>
                <div className={styles.sectionData}>
                  {data.map((field) => (
                    <ProspectReportingSummaryTermStructureRow
                      key={field}
                      field={field}
                      data={termData}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProspectReportingSummaryTermStructure
