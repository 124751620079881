import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { Form, FormSpy } from 'react-final-form'
import cn from 'classnames'
import InputAdornment from '@mui/material/InputAdornment'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { diff } from 'deep-object-diff'

import styles from './EntitySettingsArTable.module.scss'

import genericSs from '@styles/generic.module.scss'
import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'

import ActiveToolbar from '../ActiveToolbar'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import {
  debounceEventHandler,
  formatPrice,
  handleMultipleSelect,
  formatDate,
  voidHandler,
} from '../../helpers/helpers'
import { IneligibleCategory } from '@common/interfaces/bbc'
import { IEntityCollateralAggregation, IEntityCollateral } from '@common/interfaces/entityInfo'
import TextField from '../Common/TextField'
import CurrencyField from '../Common/CurrencyField'

import TableFiltersRow from '../Common/TableFiltersRow'
import Autocomplete from '../Common/Autocomplete'
import { ENTITY_SETTINGS_AR_TABLE_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import SaveState from '../Common/SaveState'

const filtersValidate = buildFiltersValidateSchema(ENTITY_SETTINGS_AR_TABLE_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(ENTITY_SETTINGS_AR_TABLE_FILTERS_CONFIG)

const INELIGIBLE_CATEGORY_LIST = Object.keys(IneligibleCategory).map((ineligibleCategory) => ({
  value: ineligibleCategory,
  label: ineligibleCategory,
}))

const mutators = {
  ...arrayMutators,
}

interface IProps {
  entityAR: ILoadingData<IEntityCollateralAggregation>
  listEntityAr: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      orderBy?: string
      orderDirection?: string
      filters?: object
    },
  ) => Promise<{ data: IEntityCollateralAggregation }>
  updateEntityAr: (
    id: string,
    itemId: string,
    data: object,
  ) => Promise<IEntityCollateralAggregation>
}

const EntitySettingsArTableRow = ({
  index,
  item,
  isActiveRow,
  isCurrentActiveRow,
  onSelectRow,
}: {
  index: number
  item: IEntityCollateral
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  onSelectRow: (event: any, index: number) => void
}) => {
  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      key={item.id}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
      })}
      onClick={handleSelectRow}
    >
      <TableCell className={genericSs.tableTextLeft}>
        <LinkButton
          component={Link}
          to={generatePath(ROUTES.CLIENT_PAGE, {
            id: item?.clientId,
          })}
        >
          {item.clientName}
        </LinkButton>
      </TableCell>
      <TableCell>
        <Autocomplete
          label=""
          className={cn('focusableInput', styles.ineligibleCategoryList)}
          tabIndex={4 * index}
          name={`entityAr[${index}].ineligibleCategory`}
          options={INELIGIBLE_CATEGORY_LIST}
          disableClearable
          withBorder
        />
      </TableCell>

      <TableCell className={genericSs.tableTextLeft}>
        {item.lastAmount && '$' + formatPrice(item.lastAmount)}
      </TableCell>

      <TableCell className={genericSs.tableTextLeft}>
        <CurrencyField
          className="focusableInput"
          size="small"
          fullWidth={false}
          tabIndex={4 * index + 1}
          name={`entityAr[${index}].creditLimits`}
        />
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <TextField
          className="focusableInput"
          tabIndex={4 * index + 2}
          size="small"
          name={`entityAr[${index}].concentrationLimit`}
          fullWidth={false}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            type: 'number',
          }}
        />
      </TableCell>

      <TableCell className={genericSs.tableTextLeft}>
        {item.lastActive && formatDate(item.lastActive)}
      </TableCell>
    </TableRow>
  )
}

const EntitySettingsArTable = ({ updateEntityAr, listEntityAr, entityAR }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const { isLoading, isSaving, isSaved, data: result } = useMemo(() => entityAR, [entityAR])
  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: 'client_name',
    direction: 'ASC',
  })
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const { id: entityId } = useParams<{ id?: string }>()

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const fetchEntityAr = useCallback(
    async (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        perPage: data.perPage || PER_PAGE,
      }
      entityId && (await listEntityAr(entityId, params))
      if (!data.loadMore) {
        setActiveItems([])
      }
    },
    [entityId, listEntityAr],
  )

  const debounceListEntityAr = useMemo(
    () => debounceEventHandler(fetchEntityAr, 500),
    [fetchEntityAr],
  )

  useEffect(() => {
    debounceListEntityAr({
      page: 0,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [filters, orderBy, debounceListEntityAr])

  const refetchEntityAr = useCallback(
    (skipLoader: boolean = false) => {
      fetchEntityAr({
        page: 0,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        skipLoader,
      })
    },
    [filters, orderBy, fetchEntityAr],
  )

  const activeItemsIds = useMemo(
    () => result?.data.filter((_, index) => activeItems.includes(index)).map(({ id }) => id),
    [activeItems, result],
  )

  const handleUpdateEntityAr = useCallback(
    async (itemId: string, data: Partial<IEntityCollateral>, activeItemsIds: string[]) => {
      if (activeItemsIds.length > 1 && activeItemsIds.includes(itemId)) {
        await updateEntityAr(id, activeItemsIds[0], {
          ...data,
          itemId: activeItemsIds,
        })
      } else {
        await updateEntityAr(id, itemId, data)
      }
      refetchEntityAr(true)
    },
    [id, updateEntityAr, refetchEntityAr],
  )

  const handleUpdateEntityArDebounce = useMemo(
    () =>
      debounceEventHandler(
        async (itemId: string, data: Partial<IEntityCollateral>, activeItemsIds: string[]) => {
          await handleUpdateEntityAr(itemId, data, activeItemsIds)
        },
        1000,
      ),
    [handleUpdateEntityAr],
  )

  const handleUpdate = useCallback(
    (props) => {
      if (props.dirty) {
        const changedRows = diff(props.initialValues.entityAr, props.values.entityAr)
        if (!Object.entries(changedRows).length) {
          return
        }

        const [[updatedRowIndex, updatedData]] = Object.entries(changedRows)
        if (Object.keys(updatedData).includes('ineligibleCategory')) {
          handleUpdateEntityAr(
            props.values.entityAr[updatedRowIndex].id,
            {
              ineligibleCategory: updatedData.ineligibleCategory?.value || null,
              clientId: props.values.entityAr[updatedRowIndex].clientId,
            },
            activeItemsIds,
          )
        } else {
          Object.keys(updatedData).forEach((field) => {
            if (updatedData[field] === undefined) {
              updatedData[field] = null
            }
          })
          handleUpdateEntityArDebounce(
            props.values.entityAr[updatedRowIndex].id,
            { ...updatedData, clientId: props.values.entityAr[updatedRowIndex].clientId },
            activeItemsIds,
          )
        }
      }
    },
    [handleUpdateEntityAr, handleUpdateEntityArDebounce, activeItemsIds],
  )

  const loadMore = useCallback(() => {
    fetchEntityAr({
      loadMore: true,
      page: Math.ceil(result?.data.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [result, orderBy, filters, fetchEntityAr])

  const totalRow = useMemo(
    () =>
      result?.data
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (summary, row) => {
            summary.lastAmount += row.lastAmount

            return summary
          },
          { totalAR: 0, totalIneligible: 0, lastAmount: 0 },
        ),
    [result, activeItems],
  )

  const initialValues = useMemo(
    () => ({
      entityAr: (result?.data || []).map((item) => ({
        ...item,
        ineligibleCategory: {
          value: item.ineligibleCategory,
          label: item.ineligibleCategory,
        },
      })),
    }),
    [result],
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={voidHandler}
      mutators={mutators}
      render={(formProps) => (
        <TableContainer
          className={cn(styles.table)}
          isActivable
          onActiveRowsChange={setActiveItems}
          onActiveRowChange={setActiveItem}
        >
          <FormSpy
            subscription={{ initialValues: true, values: true, dirty: true }}
            onChange={handleUpdate}
          />
          <Form
            onSubmit={handleFiltersChange}
            initialValues={filters}
            validate={filtersValidate}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={ENTITY_SETTINGS_AR_TABLE_FILTERS_CONFIG}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                title={
                  <Box mr={2}>
                    <h2>Receivables</h2>
                  </Box>
                }
              />
            )}
          />
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={ENTITY_SETTINGS_AR_TABLE_FILTERS_CONFIG}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
              />
            </TableHead>
            <TableBody id="scrollableTable">
              {isLoading ? (
                <TableLoader columnsCount={6} height={24} />
              ) : (
                result?.data && (
                  <InfiniteScroll
                    dataLength={result?.data.length}
                    next={loadMore}
                    hasMore={result?.data.length < result?.totals.totalItems}
                    loader={<TableLoader columnsCount={6} height={24} rowsCount={1} />}
                    scrollableTarget="scrollableTable"
                  >
                    <FieldArray name="entityAr">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <EntitySettingsArTableRow
                            key={name}
                            index={index}
                            item={formProps.values.entityAr[index]}
                            isActiveRow={activeItems.includes(index)}
                            isCurrentActiveRow={activeItem === index}
                            onSelectRow={handleSelectRow}
                          />
                        ))
                      }
                    </FieldArray>
                  </InfiniteScroll>
                )
              )}
            </TableBody>
          </Table>

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <SaveState isSaving={isSaving} isSaved={isSaved} />
          </Box>

          <ActiveToolbar
            activeItems={activeItems}
            className={styles.activeToolbar}
            containerRef={wrapperRef}
            resetActiveItems={resetActiveItems}
          >
            <div className={genericSs.tableTextLeft}>
              <span className={cn(genericSs.pricePrefix, styles.priceAdornment)}>$</span>
              {formatPrice(totalRow?.lastAmount)}
            </div>
          </ActiveToolbar>
        </TableContainer>
      )}
    />
  )
}

export default EntitySettingsArTable
