import React from 'react'
import { generatePath } from 'react-router'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './NotificationItem.module.scss'

import AtlasLogo from '@assets/images/atlas-logo.png'
import { IUser } from '@common/interfaces/user'
import { formatDateCalendar, formatDateTime } from '../../helpers/helpers'
import { INotification, NotificationType } from '@common/interfaces/notification'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { ROUTES } from '../../constants/routes'
import { DueDiligenceDocumentRequestStep } from '@common/interfaces/dueDiligence'
import { IClientInfo } from '@common/interfaces/client'
import { htmlToText } from '@common/helpers/helpers'
import { LCR_COMMENTARY_SECTION_PAGE } from '@common/constants/lcrCommentary'

// Has copy in server/src/services/notificationSender.ts
// Should be updated in both places
export const generateNotificationLocation = (
  notification: INotification,
  isAdmin: boolean = false,
  isBDO: boolean = false,
  isUW: boolean = false,
  isClientUser: boolean = false,
): string | null => {
  if (
    [
      NotificationType.NoteTagging,
      NotificationType.NoteReply,
      NotificationType.LCRNoteReply,
      // NotificationType.DefaultDiligenceNoteEdit, TODO: Revert default note edit notficiation
      NotificationType.DefaultDiligenceNoteReply,
    ].includes(notification.type)
  ) {
    if (!notification.note) {
      return null
    }
    if (notification.note?.lcrCommentary) {
      return `${generatePath(ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAGE, {
        id: notification.note.clientInfo.id,
      })}${LCR_COMMENTARY_SECTION_PAGE[notification.note.lcrCommentary.section]}`
    }
    if (notification.note.borrowingBase) {
      return `${generatePath(ROUTES.BBC_SUMMARY, {
        id: notification.note.borrowingBase.id,
      })}?noteId=${notification.note.id}`
    }
    if (notification.note.opsReporting) {
      return `${generatePath(ROUTES.PROSPECT_PAGE, {
        id: notification.note.opsReporting.id,
      })}?noteId=${notification.note.id}`
    }
    if (notification.note.ongoingReporting) {
      switch (notification.note.ongoingReporting.type) {
        case REPORTING_DOCUMENT_TYPES.financials:
          return `${generatePath(ROUTES.ONGOING_REPORTING_SUMMARY, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        case REPORTING_DOCUMENT_TYPES.bankTransactions:
          return `${generatePath(ROUTES.ONGOING_REPORTING_BANK_TRANSACTIONS_UPLOAD, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        case REPORTING_DOCUMENT_TYPES.salesBySKU:
          return `${generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        case REPORTING_DOCUMENT_TYPES.arGeneralLedger:
          return `${generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        case REPORTING_DOCUMENT_TYPES.capTable:
          return `${generatePath(ROUTES.ONGOING_REPORTING_CAP_TABLE_UPLOAD, {
            id: notification.note.ongoingReporting.id,
          })}?noteId=${notification.note.id}`
        default:
          return null
      }
    }
    if (notification.note.clientInfo) {
      return `${generatePath(ROUTES.CLIENT_PAGE, { id: notification.note.clientInfo.id })}?noteId=${
        notification.note.id
      }`
    }
    const entityNoteMentions = notification.note.noteMentions?.find(({ entity }) => !!entity)
    if (entityNoteMentions) {
      return `${generatePath(ROUTES.ENTITY_PAGE, { id: entityNoteMentions.entityId })}?noteId=${
        notification.note.id
      }`
    }
    return null
  }

  if (notification.type === NotificationType.RequestFunding && notification.borrowingBaseId) {
    return generatePath(ROUTES.BBC_SUMMARY, { id: notification.borrowingBaseId })
  }

  if (
    [NotificationType.SubmitReporting, NotificationType.CodatPortfolioSync].includes(
      notification.type,
    )
  ) {
    return `${ROUTES.ANALYSIS_QUEUE}`
  }

  if (notification.type === NotificationType.OPSSync && notification.data?.opsReportingId) {
    return generatePath(ROUTES.PROSPECT_ANALYSIS_QUEUE, { id: notification.data?.opsReportingId })
  }

  if (
    [
      NotificationType.ActivityAssign,
      NotificationType.ChecksPostingIssue,
      NotificationType.BBCProcessingSkipped,
    ].includes(notification.type)
  ) {
    return ROUTES.ACTIVITY_QUEUE
  }

  if (
    notification.type === NotificationType.ClientIntakeSubmit &&
    notification.data?.opsReportingId
  ) {
    return generatePath(ROUTES.PROSPECT_APPLICATION_PAGE, { id: notification.data.opsReportingId })
  }
  if (
    notification.type === NotificationType.ClientIntakeSubmit &&
    notification.clientInfo?.opsReportings?.length
  ) {
    return generatePath(ROUTES.PROSPECT_APPLICATION_PAGE, {
      id: notification.clientInfo.opsReportings[0].id,
    })
  }

  if (
    notification.type === NotificationType.ClientApplicationFileUpload &&
    notification.clientInfo
  ) {
    return `${generatePath(ROUTES.DUE_DILIGENCE_VALIDATE_DOCUMENTS_PAGE, {
      id: notification.clientInfo.id,
    })}?documentRequestId=${notification.ddDocumentRequestId}`
  }

  if (notification.type === NotificationType.CodatUWSync && notification.clientInfo) {
    return `${generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, {
      id: notification.clientInfo.id,
    })}`
  }

  if (
    notification.type === NotificationType.ClientApplicationStepFinished &&
    notification.clientInfo
  ) {
    if (notification.data.step === DueDiligenceDocumentRequestStep.Team) {
      return `${generatePath(ROUTES.DUE_DILIGENCE_TEAM_PAGE, {
        id: notification.clientInfo.id,
      })}`
    }
    if (notification.data.step === DueDiligenceDocumentRequestStep.Financials) {
      return `${generatePath(ROUTES.DUE_DILIGENCE_CLIENT_FINANCIALS_PAGE, {
        id: notification.clientInfo.id,
      })}`
    }
    return `${generatePath(ROUTES.DUE_DILIGENCE, {
      id: notification.clientInfo.id,
    })}`
  }

  if (notification.type === NotificationType.ClientApplicationDocumentComment) {
    if (isClientUser) {
      return `${generatePath(ROUTES.DOCUMENT_REQUESTS)}?documentRequestId=${
        notification.ddDocumentRequestId
      }`
    } else {
      return `${generatePath(ROUTES.DUE_DILIGENCE_VALIDATE_DOCUMENTS_PAGE, {
        id: notification.clientInfo?.id,
      })}?documentRequestId=${notification.ddDocumentRequestId}&isComment=true`
    }
  }

  if (
    [
      NotificationType.OveradvanceCreated,
      NotificationType.OveradvanceUpdated,
      NotificationType.OveradvanceUpdatedPriority,
    ].includes(notification.type) &&
    notification.clientInfo
  ) {
    return generatePath(ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_OVERADVANCE, {
      id: notification.clientInfo.id,
    })
  }

  if (
    [
      NotificationType.AmendmentCreated,
      NotificationType.AmendmentUpdated,
      NotificationType.AmendmentDeleted,
    ].includes(notification.type) &&
    notification.clientInfo
  ) {
    return `${generatePath(ROUTES.CLIENT_PAGE, {
      id: notification.clientInfo.id,
    })}?tab=Overview&scrollTo=amendments`
  }

  if (
    [
      NotificationType.TermLoanCreated,
      NotificationType.TermLoanUpdated,
      NotificationType.TermLoanDeleted,
      NotificationType.TermLoanActivityCreated,
      NotificationType.TermLoanActivityUpdated,
      NotificationType.TermLoanActivityDeleted,
    ].includes(notification.type) &&
    notification.clientInfo
  ) {
    return `${generatePath(ROUTES.CLIENT_PAGE, {
      id: notification.clientInfo.id,
    })}?tab=Overview&scrollTo=term-loans`
  }

  if (notification.clientInfo && isUW) {
    return generatePath(ROUTES.DUE_DILIGENCE, { id: notification.clientInfo.id })
  }

  if (notification.clientInfo && isBDO && notification.clientInfo.opsReportings?.length) {
    return generatePath(ROUTES.PROSPECT_PAGE, { id: notification.clientInfo.opsReportings[0].id })
  }

  if (notification.clientInfo) {
    return generatePath(ROUTES.CLIENT_PAGE, { id: notification.clientInfo.id })
  }

  return null
}

export const NotificationItemAvatar = ({
  className,
  user,
  clientInfo,
}: {
  className?: string
  user: IUser
  clientInfo?: IClientInfo
}) => {
  return (
    <Avatar
      className={className}
      alt={
        clientInfo ? clientInfo.clientName : user ? `${user.firstName} ${user.lastName}` : 'Atlas'
      }
      src={clientInfo ? clientInfo.iconUrl : user ? user?.avatar : AtlasLogo}
    >
      {clientInfo ? clientInfo.clientName[0] : `${user?.firstName[0]} ${user?.lastName[0]}`}
    </Avatar>
  )
}

export const NotificationItemDate = ({ createdAt }: { createdAt: string }) => {
  return (
    <Tooltip title={formatDateTime(createdAt)}>
      <span>{formatDateCalendar(createdAt)}</span>
    </Tooltip>
  )
}
export const NotificationItemUnreadIcon = () => {
  return <div className={styles.notificationItemUnreadIcon} />
}

const NotificationItemContentText = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div
      className={cn(styles.notificationItemContent, className)}
      dangerouslySetInnerHTML={{
        __html: notification.notificationText,
      }}
    />
  )
}

const NotificationItemContentNoteTagging = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  if (!notification.note) {
    return null
  }

  return (
    <div className={cn(styles.notificationItemContent, className)}>
      <div
        dangerouslySetInnerHTML={{
          __html: notification.notificationText,
        }}
      />
      <div className={styles.notificationItemContentNote}>{notification.note.noteText}</div>
    </div>
  )
}

const NotificationItemContentDueDiligenceDocumentComment = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      <div
        dangerouslySetInnerHTML={{
          __html: notification.notificationText,
        }}
      />
      <div className={styles.notificationItemContentNote}>
        {htmlToText(
          notification.ddDocumentRequestComment?.comment || notification.data.comment || '',
        )}
      </div>
    </div>
  )
}

const NotificationItemNoteReplyContent = ({
  className,
  notification,
}: {
  className?: string
  notification: INotification
}) => {
  return (
    <div className={cn(styles.notificationItemContent, className)}>
      <div
        dangerouslySetInnerHTML={{
          __html: notification.notificationText,
        }}
      />
      <div className={styles.notificationItemContentNote}>{notification.data.replyNoteText}</div>
    </div>
  )
}

const COMPONENT_MAP = {
  [NotificationType.NoteTagging]: NotificationItemContentNoteTagging,
  [NotificationType.ClientApplicationDocumentComment]:
    NotificationItemContentDueDiligenceDocumentComment,
  [NotificationType.NoteReply]: NotificationItemNoteReplyContent,
  [NotificationType.DefaultDiligenceNoteReply]: NotificationItemNoteReplyContent,
  [NotificationType.DefaultDiligenceNoteEdit]: NotificationItemNoteReplyContent,
  [NotificationType.LCRNoteReply]: NotificationItemNoteReplyContent,
}

export const NotificationItemContent = ({
  className,
  notification,
  isFull = true,
}: {
  className?: string
  notification: INotification
  isFull?: boolean
}): any => {
  const Component = COMPONENT_MAP[notification.type] || NotificationItemContentText

  return <Component className={className} notification={notification} isFull={isFull} />
}
