import { ICheckAccount } from './collection'

export interface ICreditorInfo {
  id: string
  creditor: string
}

export interface IDebtorInfo {
  id: string
  debtor: string
}

export interface IEntityInfo {
  id: string
  name: string
  iconUrl?: string
  website?: string
  debtor: boolean
  creditor: boolean
  investor: boolean
  acquirer: boolean
  salesforceId?: string
  salesforceType?: string
  description?: string
  salesforceLink?: string
  entityType?: string
  totalAr?: number
  ineligibleAr?: number
  totalExposure?: number
  totalAp?: number
  totalArPct?: number
  totalApPct?: number
  ar0To30Days?: number
  ar31To60Days?: number
  ar61To90Days?: number
  ar91PlusDays?: number
  ap0To30Days?: number
  ap31To60Days?: number
  ap91PlusDays?: number
  ap61To90Days?: number
  totalAp91Plus?: number
  totalCollections?: number
  dilution?: number
  averageDSO?: number
  newInvoices?: number
  newBills?: number
  dso?: number
  averageTerms?: number
  type?: EntitySubType
  entitiesWithDuplicates?: IEntitiesWithDuplicates[]
  clientId?: string
  industries?: string
  subsidiaries?: IEntityInfo[]
  parent: IEntityInfo
  totalClients?: number
  totalClientsAr?: number
  totalClientsAp?: number
}

export interface IInvestorSummaryData {
  id: string
  investor: string
  totalInvestment: number
  totalClients: number
  totalDiligenceClients: number
  totalBoardSeats: number
  averageInvestment: number
  avgFullyDilutedOwnership: number
}

export interface IEntitiesWithDuplicates {
  id: string
  entityId: string
  duplicateEntityId: string
  isRejected: boolean
  duplicateEntity: IEntityInfo
}
export interface IEntityMergeConflicts {
  id: string
  clientName: string
}
export interface IEntityActivity {
  clientName: string
  clientId: string
  recordDate: string
  amount: number
  invoice?: string
  type: string
  boxLink?: string
  totalItems?: number
}

interface IReportSummaryStatsTotals {
  _1To_30Days: number
  _1To_30DaysPercent: number
  _31To_60Days: number
  _31To_60DaysPercent: number
  _61To_90Days: number
  _61To_90DaysPercent: number
  _91PlusDays: number
  _91PlusDaysPercent: number
  ineligible?: number
  ineligiblePercent?: number
  total: number
  totalExposure: number
  dso?: number
  averageTerms?: number
  newInvoices?: number
}

export interface IReportSummaryStats {
  clientId?: string
  dso?: number
  averageTerms?: number
  newInvoices?: number
  invoice: string | null
  daysPastInvoice: number | null
  _1To_30Days: number
  _31To_60Days: number
  _61To_90Days: number
  _91PlusDays: number
  _91PlusDaysPercent: number
  ineligible: number
  total: number
  totalExposure: number
  percent: number
  clientName: string
  invoiceCount: number
  totalCount: number
  rows?: IReportSummaryStats[]
}

export interface IEntityReportStatsData {
  data: IReportSummaryStats[]
  totals: IReportSummaryStatsTotals
}

export interface IEntityDilutionData {
  data: IEntityDilution[]
  totals: IEntityDilutionTotals
}

export interface IEntityDilution {
  clientName: string
  id: string
  totalAr: number
  priorAr: number
  newInvoices: number
  totalCollections: number
  impliedCollections: number
  dilution: number
}

interface IEntityDilutionTotals {
  priorAr: number
  totalAr: number
  newInvoices: number
  impliedCollections: number
  totalCollections: number
  dilution: number
}

export interface IEntityActivityData {
  data: IEntityActivity[]
  totalCount: number
  clients: string[]
}

interface ICustomerSummary {
  id: string
  name: string
  totalAr: number
  totalExposure: number
  totalCollections: number
  dilution: number
  dso: number
  totalClientsAr?: number
}

interface IVendorSummary {
  id: string
  name: string
  totalAp: number
  totalClients: number
  entitytype: string
  industries: string
  totalClientsAp?: number
}

export interface ICustomerAggregation {
  data: ICustomerSummary[]
  totalItems: number
}

export interface IEntityCollateralAggregation {
  data: IEntityCollateral[]
  totals: {
    totalItems: number
  }
}

export interface IVendorAggregation {
  data: IVendorSummary[]
  totalItems: number
}

export interface IEntityCollateral {
  id: string
  ineligibleCategory?: string
  creditLimits?: number
  concentrationLimit?: number
  totalAR?: number
  totalIneligible?: number
  isCritical?: boolean
  entityInfo?: IEntityInfo
  clientName?: string
  lastActive?: string
  lastAmount?: number
  clientId?: string
  priority?: boolean
  priorityPayable?: string
  type?: string
}

export enum RelatedEntityType {
  Subsidiaries = 'subsidiaries',
  All = 'all',
}

export enum EntityType {
  Debtor = 'debtor',
  Creditor = 'creditor',
  Investor = 'investor',
}

export interface IOptionType {
  id?: string
  label?: string
  value?: string
  inputValue?: string
  type?: string
  isNew?: boolean
}

export interface IInvestorAggregation {
  data: IInvestorAggregationData[]
  totals: {
    totalItems: number
  }
}

export enum EntitySubType {
  BNPL = 'BNPL',
  PaymentProcessor = 'Payment Processor',
  BillPaymentProvider = 'Bill Payment Provider',
}

export const ENTITY_SUB_TYPE_OPTIONS = Object.values(EntitySubType).map((value) => ({
  value,
  label: value,
}))

export interface IInvestorAggregationData {
  id: string
  investor: string
  totalInvestment: number
  averageInvestment: number
  avgOutstandingOwnership: number
  avgFullyDilutedOwnership: number
  totalClients: number
  totalBoardSeats: number
  entityId: string
  totalProspects: number
}

export interface IInvestorAggregation {
  data: IInvestorAggregationData[]
  totals: {
    totalItems: number
  }
}

export interface IInvestmentRound {
  round: string
  totalInvestment: number
}

export interface IInvestmentRowDetailData {
  investor: string
  rounds: IInvestmentRound[]
}

export interface IInvestmentRowDetail {
  data: IInvestmentRowDetailData
}

export enum EntityCateogry {
  Customer = 'Customer',
  Vendor = 'Vendor',
  Investor = 'Investor',
}

export interface IEntityAccountsData {
  data: ICheckAccount[]
  totals: {
    totalItems: number
  }
}

export interface IEntityPreviewInfo {
  id: string
  name: string
  debtor: boolean
  creditor: boolean
  iconUrl?: string
  website?: string
  description?: string
  totalAr: number
  ineligibleAr: number
  totalExposure: number
  dilution: number
  dso: number
  dsoOnClient: number
  averageTerms: number
  averageTermsOnClient: number
  avgCreditLimit?: number
  avgConcentrationLimit?: number
  eligibleClientsCount?: number
  totalClientsAr?: number
  totalAp: number
  priorityClientsCount?: number
  totalClientsAp?: number
  avgReserveAp?: number
  clients: {
    clientId: string
    clientName: string
  }[]
  prospects: {
    clientId: string
    clientName: string
  }[]
}
