import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import { makeValidate } from 'mui-rff'
import { Form, FormSpy } from 'react-final-form'
import * as Yup from 'yup'
import styles from '../ProspectReportingPayablesPage/ProspectReportingPayablesPage.module.scss'
import { OngoingReportingType } from '@common/interfaces/bbc'
import {
  IOPSReporting,
  IProspectARSummary,
  OPSReportingFlowStatus,
  OPSReportingStatus,
} from '@common/interfaces/prospects'
import SummaryTable from '../../components/ProspectArApSummary'
import { dateToString, debounceEventHandler } from '../../helpers/helpers'
import KeyboardDatePicker from '../../components/Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../constants/common'
import { formatDate } from '../../helpers/helpers'
import Modal from '../../components/Common/Modal'
import ClientAliasMapping from '../../components/ClientAliasMapping'
import ProspectDashboardHeader from '../../components/ProspectDashboardHeader'
import ProspectFileSelect from '../../components/ProspectFileSelect'
import { EditMapping, ExternalLink, EditSourceData } from '../../components/Common/Icons'
import ProspectReportingLoader from '../../components/ProspectReportingLoader'
import { ILoadingData } from '../../redux/types'
import { ClientInfoStatus } from '@common/interfaces/client'
import TextField from '../../components/Common/TextField'
import SaveState from '../../components/Common/SaveState'
import OPSWaivedDocument from '../../components/OPSWaivedDocument'
import { usePermissions } from '../../helpers/permissionContext'

const schema = Yup.object().shape({
  crossAge: Yup.number()
    .required('Cross Age is required')
    .typeError('Invalid number')
    .min(0, 'Cross Age must be greater than or equal 0')
    .max(100, 'Cross Age be less than or equal 100'),
  concentrationLimit: Yup.number()
    .required('Concentration Limit is required')
    .typeError('Invalid number')
    .min(0, 'Concentration Limit must be greater than or equal 0')
    .max(100, 'Concentration Limit be less than or equal 100'),
  advanceRate: Yup.number()
    .required('Advance Rate is required')
    .typeError('Invalid number')
    .min(0, 'Advance Rate must be greater than or equal 0')
    .max(100, 'Advance Rate be less than or equal 100'),
})

const validate = makeValidate(schema)

interface IProps {
  reporting: IOPSReporting
  show: (id: string) => void
  startReporting: (id: string, data?: object) => void
  arSummaryData: ILoadingData<IProspectARSummary>
  listARSummary: (id: string, params: object) => void
  updateARSummary: (id: string, params: object) => Promise<void>
  updateDate: (data: object) => Promise<any>
  updateReportingFlowAdditionalData: (id: string, flowId: string, data: object) => Promise<any>
  date: { AP: string; AR: string }
  getDate: ({ fileId }: { fileId: string }) => void
}

const ProspectReportingReceivablesPage = ({
  reporting,
  show,
  startReporting,
  arSummaryData,
  listARSummary,
  updateARSummary,
  updateDate,
  updateReportingFlowAdditionalData,
  date,
  getDate,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()

  const isDataUnmapped = useMemo(
    () => arSummaryData?.data?.data?.filter((item) => item.debtor === null).length > 0,
    [arSummaryData],
  )

  const [refreshCounter, setRefreshCounter] = useState(0)
  const [refreshCounterWithoutLoader, setRefreshCounterWithoutLoader] = useState(0)
  const [isFilesSaving, setIsFilesSaving] = useState(false)
  const [isMappingModalShown, setIsMappingModalShown] = useState(isDataUnmapped)
  const togglesMappingModalShown = useCallback(() => setIsMappingModalShown((prev) => !prev), [])

  const opsReportingFlow = useMemo(
    () => reporting?.opsReportingFlows?.filter(({ type }) => type === OngoingReportingType.AR)[0],
    [reporting],
  )
  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )
  const isActionRequired = useMemo(() => {
    if (readOnly) {
      return false
    }
    const opsReportingFlow = reporting?.opsReportingFlows?.filter(
      ({ type }) => type === OngoingReportingType.AR,
    )[0]
    return ![OPSReportingFlowStatus.Complete, OPSReportingFlowStatus.Waived].includes(
      opsReportingFlow?.status,
    )
  }, [reporting, readOnly])
  const isFileRequired = useMemo(() => {
    const opsReportingFlow = reporting?.opsReportingFlows?.filter(
      ({ type }) => type === OngoingReportingType.AR,
    )[0]
    return !opsReportingFlow?.files?.[0]?.fileId
  }, [reporting])

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)

  const handleCloseModal = useCallback(async () => {
    setRefreshCounter((counter) => counter + 1)
    setIsMappingModalShown(false)
    show(id)
  }, [setIsMappingModalShown, show, id])

  const fileIdToUse = useMemo(() => opsReportingFlow?.files?.[0]?.fileId, [opsReportingFlow])

  useEffect(() => {
    if (reporting?.id && !readOnly) {
      startReporting(reporting.id)
    }
  }, [reporting?.id, readOnly, startReporting])

  useEffect(() => {
    if (fileIdToUse) {
      getDate({ fileId: fileIdToUse })
    }
  }, [fileIdToUse, getDate])

  const handleChangeDate = useCallback(
    async (date: any) => {
      const currentAsOfDate = dateToString(date.date)
      const updatedDates = { fileId: fileIdToUse, recordDate: currentAsOfDate }
      if (currentAsOfDate && fileIdToUse) {
        await updateDate(updatedDates)
        setRefreshCounter((counter) => counter + 1)
      }
    },
    [updateDate, fileIdToUse],
  )

  const additionalDataInitialValues = useMemo(
    () => ({
      crossAge: opsReportingFlow?.crossAge * 100 || 30,
      concentrationLimit: opsReportingFlow?.concentrationLimit * 100 || 30,
      advanceRate: opsReportingFlow?.advanceRate * 100 || 85,
    }),
    [opsReportingFlow],
  )

  const handleChangeAdditionalData = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        await updateReportingFlowAdditionalData(id, opsReportingFlow.id, {
          crossAge: (+data.crossAge || 0) / 100,
          concentrationLimit: (+data.concentrationLimit || 0) / 100,
          advanceRate: (+data.advanceRate || 0) / 100,
        })
        setRefreshCounterWithoutLoader((counter) => counter + 1)
      }, 500),
    [id, opsReportingFlow, updateReportingFlowAdditionalData],
  )

  const initialValueDate = useMemo(
    () => ({
      date: date?.AR,
    }),
    [date],
  )

  const selectedFile = useMemo(
    () => reporting?.files.filter(({ id }) => id === fileIdToUse)[0],
    [fileIdToUse, reporting],
  )

  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(() => {
    setIsFileSelectShown(false)
    show(id)
  }, [setIsFileSelectShown, show, id])

  const handleUpdateARSummary = useCallback(
    async (id, data: object) => {
      await updateARSummary(id, data)
      setRefreshCounterWithoutLoader((counter) => counter + 1)
    },
    [updateARSummary],
  )

  const isWaived = useMemo(
    () => opsReportingFlow?.status === OPSReportingFlowStatus.Waived,
    [opsReportingFlow],
  )

  return (
    <Box py={1} pr={2} className={styles.container}>
      <ProspectDashboardHeader setRefreshCounter={setRefreshCounter} />
      {!readOnly && opsReportingFlow && (
        <ProspectFileSelect
          isOpen={isFileSelectShown}
          opsReportingFlow={opsReportingFlow}
          reportType={OngoingReportingType.AR}
          setRefreshCounter={setRefreshCounter}
          setIsFilesSaving={setIsFilesSaving}
          handleOpenModal={handleOpenSelectFile}
          handleCloseModal={handleCloseSelectFile}
        />
      )}

      <Grid item xs={12}>
        {opsReportingFlow ? (
          isWaived ? (
            <OPSWaivedDocument
              reportType={OngoingReportingType.AR}
              openFileSelectModal={handleOpenSelectFile}
            />
          ) : (
            <SummaryTable
              reportType="AR"
              listSummary={listARSummary}
              updateSummary={handleUpdateARSummary}
              summaryData={arSummaryData}
              refreshCounter={refreshCounter}
              refreshCounterWithoutLoader={refreshCounterWithoutLoader}
              title={
                <Grid
                  container
                  columnSpacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Form
                      onSubmit={handleChangeAdditionalData}
                      initialValues={additionalDataInitialValues}
                      validate={validate}
                      render={({ handleSubmit }) => (
                        <Box display="flex" gap={2}>
                          <TextField
                            className={styles.textField}
                            name="crossAge"
                            label="Cross Age"
                            disabled={readOnly}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              type: 'number',
                            }}
                          />
                          <TextField
                            className={styles.textField}
                            name="concentrationLimit"
                            label="Conc. Limit"
                            disabled={readOnly}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              type: 'number',
                            }}
                          />
                          <TextField
                            className={styles.textField}
                            name="advanceRate"
                            label="Advance Rate"
                            disabled={readOnly}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              type: 'number',
                            }}
                          />
                          <FormSpy
                            subscription={{ valid: true, dirty: true, values: true }}
                            onChange={(props) => {
                              props.valid && props.dirty && handleSubmit(props.values)
                            }}
                          />
                        </Box>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Form
                      onSubmit={handleChangeDate}
                      initialValues={initialValueDate}
                      render={({
                        values,
                        handleSubmit,
                      }: {
                        values: any
                        handleSubmit: (e: any) => void
                      }) => (
                        <div className={styles.tableCellNumber}>
                          <KeyboardDatePicker
                            name={`date`}
                            placeholder="As of Date"
                            inputFormat={DATE_FORMAT}
                            value={formatDate(values.date)}
                            onChange={handleSubmit}
                            disabled={readOnly}
                            withTopLabel
                          />
                          <FormSpy
                            subscription={{ dirty: true, values: true }}
                            onChange={(props) => {
                              props.dirty && handleSubmit(props.values)
                            }}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    {selectedFile?.link && (
                      <ExternalLink link={selectedFile?.link} title={'AR aging'} />
                    )}
                  </Grid>
                  {!readOnly && (
                    <Grid item>
                      <EditSourceData action={handleOpenSelectFile} error={!!selectedFile?.error} />
                    </Grid>
                  )}
                  <Grid item mr={2}>
                    {!isUW && (
                      <EditMapping
                        mappingRequired={isActionRequired}
                        mappingDisabled={isFileRequired}
                        action={togglesMappingModalShown}
                      />
                    )}
                  </Grid>
                </Grid>
              }
              tableClassName={styles.arSummaryTable}
              actionsSize={8}
              readOnly={readOnly}
              footer={
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  <SaveState isSaving={arSummaryData?.isSaving} isSaved={arSummaryData?.isSaved} />
                </Box>
              }
              isFilesSaving={isFilesSaving}
            />
          )
        ) : (
          <ProspectReportingLoader reportType="AR" />
        )}
      </Grid>
      {isMappingModalShown && (
        <div className={styles.modal}>
          <Modal
            classes={{
              root: styles.modal,
            }}
            open
            onCancel={handleCloseModal}
            toolbar
            disableEnforceFocus
            title="Mapping"
            isTable
          >
            <ClientAliasMapping
              fileId={fileIdToUse}
              table="prospect_ar"
              clientName={reporting?.clientName}
              isProspect
              tableClassName={styles.aliasesTable}
              readOnly={readOnly}
            />
          </Modal>
        </div>
      )}
    </Box>
  )
}

export default ProspectReportingReceivablesPage
