import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useParams, useHistory } from 'react-router'
import { Form, FormSpy } from 'react-final-form'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import cn from 'classnames'

import styles from './ProspectAddTermsPage.module.scss'
import termStructureStyles from '../../components/ProspectReportingSummaryTermStructure/ProspectReportingSummaryTermStructure.module.scss'

import { IProspectTerms, IOPSReporting, OPSReportingStatus } from '@common/interfaces/prospects'
import { generateSchemaValidator } from './helpers'
import {
  All_MANAGE_TERMS_FIELDS_CONFIG,
  TERM_STRUCTURE_FIELDS,
  TERM_STRUCTURE_FIELDS_CONFIG,
  AR_SPECIFIC_TERMS_FIELDS,
  INVENTORY_SPECIFIC_TERMS_FIELDS,
} from '@common/constants/prospects'
import Card from '../../components/Common/Card'
import FormField from '../../components/Common/FormField'
import Button from '../../components/Common/Button/Button'
import { ROUTES } from '../../constants/routes'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { ILoadingData } from '../../redux/types'
import SaveState from '../../components/Common/SaveState'
import RouteLeavingGuard from '../../components/Common/RouteLeavingGuard'
import { LOAN_TYPES } from '@common/constants/client'
import { ClientInfoStatus } from '@common/interfaces/client'
import { usePermissions } from '../../helpers/permissionContext'

const validate = generateSchemaValidator(
  All_MANAGE_TERMS_FIELDS_CONFIG.map((field) =>
    field.name === 'recordDate'
      ? {
          ...field,
          isRequired: true,
        }
      : field,
  ),
)

const ProspectTermsField = ({
  field,
  data,
  readOnly,
}: {
  field: string
  data: IProspectTerms
  readOnly: boolean
}) => {
  const fieldWithConfig = useMemo(
    () => TERM_STRUCTURE_FIELDS_CONFIG.find(({ value }) => value === field),
    [field],
  )

  if (
    !fieldWithConfig ||
    (AR_SPECIFIC_TERMS_FIELDS.includes(field) &&
      (!data?.loanType || data.loanType === LOAN_TYPES.inventory)) ||
    (INVENTORY_SPECIFIC_TERMS_FIELDS.includes(field) &&
      (!data?.loanType || data.loanType === LOAN_TYPES.ar)) ||
    ('qualifiedEquityRound' === field && !data?.covenantType)
  ) {
    return null
  }

  return (
    <div className={termStructureStyles.dataRow}>
      <div className={cn(termStructureStyles.label, styles.label)}>{fieldWithConfig.label}</div>
      <div className={styles.value}>
        {data ? (
          <FormField
            name={fieldWithConfig.value}
            type={fieldWithConfig.type}
            options={fieldWithConfig.options}
            disabled={readOnly || fieldWithConfig.disabled}
            withClear
            size="small"
          />
        ) : (
          <Skeleton width={100} height={15} />
        )}
      </div>
    </div>
  )
}

interface IProps {
  prospectTerms: ILoadingData<{ data: IProspectTerms[] }>
  reporting: IOPSReporting
  createProspectTerms: (id: string, term: IProspectTerms) => Promise<any>
  updateProspectTerms: (id: string, prospectTermId: string, term: IProspectTerms) => Promise<any>
  show: (id: string) => void
  listProspectTerms: (id: string) => void
}

const ProspectTerms = ({
  prospectTerms,
  reporting,
  createProspectTerms,
  updateProspectTerms,
  show,
  listProspectTerms,
}: IProps) => {
  const { id, termId } = useParams<{ id: string; termId: string }>()
  const [isDirty, setIsDirty] = useState(false)

  useLoadInfo({ id, info: reporting, show })
  const title = useMemo(() => (termId ? 'Edit terms' : 'Add terms'), [termId])
  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  useSetPageTitle(title)

  const history = useHistory()

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  useEffect(() => {
    if (id || termId) {
      listProspectTerms(id)
    }
  }, [id, listProspectTerms, termId])

  const {
    data: prospectTermsData,
    isSaving,
    isSaved,
    isLoading,
  } = useMemo(
    () => ({
      data: prospectTerms?.data?.data,
      isLoading: prospectTerms.isLoading,
      isSaving: prospectTerms.isSaving,
      isSaved: prospectTerms.isSaved,
    }),
    [prospectTerms],
  )

  const prospectTerm = useMemo(() => {
    if (prospectTermsData && termId) {
      return prospectTermsData.find((term) => term.id === termId)
    } else if (prospectTermsData && prospectTermsData.length >= 1) {
      const newTerm = prospectTermsData[prospectTermsData.length - 1]
      delete newTerm.id
      return newTerm
    }
  }, [prospectTermsData, termId])

  const isEdit = useMemo(() => !!prospectTerm?.id, [prospectTerm])

  const handleSubmit = useCallback(
    async (values: any) => {
      All_MANAGE_TERMS_FIELDS_CONFIG.forEach(({ name }) => {
        if (values[name] === undefined) {
          values[name] = null
        }
      })

      let result
      if (isEdit) {
        result = await updateProspectTerms(reporting?.id, prospectTerm.id, values)
        setIsDirty(false)
      } else {
        result = await createProspectTerms(reporting?.id, values)
        setIsDirty(false)
      }
      if (!result.error) {
        history.push(generatePath(ROUTES.PROSPECT_MANAGE_TERMS_PAGE, { id }))
      }
    },
    [prospectTerm, reporting, createProspectTerms, updateProspectTerms, isEdit, history, id],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        link: ROUTES.PROSPECTS,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.PROSPECT_PAGE, { id }),
        title: reporting?.clientName,
      },
      {
        link: generatePath(ROUTES.PROSPECT_MANAGE_TERMS_PAGE, { id }),
        title: 'Manage terms',
      },
      {
        title: title,
        link: '#',
      },
    ],
    [reporting, id, title],
  )

  useEffect(() => {
    if (!isEdit) {
      setIsDirty(true)
    }
  }, [isEdit])

  return (
    <Grid container py={3} pr={2} alignItems={'flex-start'} justifyContent={'start'} rowSpacing={2}>
      <Grid item container xs={12} justifyContent={'space-between'}>
        <Grid item container xs={6} justifyContent={'flex-start'} alignItems={'center'}>
          <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Form<any, any>
          initialValues={prospectTerm}
          onSubmit={handleSubmit}
          validate={validate}
          render={({ form, values, handleSubmit, dirtyFields }) => {
            return (
              <Card
                className={styles.card}
                title={
                  <Grid container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={6}>
                      <span>Asset based loan</span>
                    </Grid>
                    <Grid item container xs={6} justifyContent={'flex-end'}>
                      <SaveState isSaving={isSaving} isSaved={isSaved} />

                      {!readOnly && (
                        <Button
                          type="submit"
                          small={false}
                          className={styles.editButtonIcon}
                          disabled={!dirtyFields}
                          onClick={handleSubmit}
                          isLoading={isSaving || isLoading}
                        >
                          Save
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
                withBorder={false}
              >
                <div className={termStructureStyles.data}>
                  {TERM_STRUCTURE_FIELDS.map((data, index) => (
                    <div key={index} className={termStructureStyles.column}>
                      {data.map(({ section, data }) => (
                        <div key={section} className={termStructureStyles.section}>
                          <div className={termStructureStyles.sectionTitle}>{section}</div>

                          <div className={termStructureStyles.sectionData}>
                            {data.map((field) => (
                              <ProspectTermsField
                                key={field}
                                field={field}
                                data={values}
                                readOnly={readOnly}
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <div className={styles.longTextRow}>
                  <div className={cn(termStructureStyles.label, styles.label)}>
                    Comments for committee
                  </div>

                  <FormField name="commentsForCommittee" type="text" disabled={readOnly} rows={4} />
                </div>

                <RouteLeavingGuard
                  when={isDirty}
                  navigate={handleNavigate}
                  shouldBlockNavigation={() => isDirty}
                  helperText="You have unsaved changes. Are you sure you want to leave?"
                  buttonText="Save changes"
                  alternateSubmit={() => handleSubmit(values)}
                  isAlternateSubmitInvalid={form.getState().invalid}
                />

                <FormSpy
                  subscription={{ dirty: true, valid: true, touched: true, dirtyFields: true }}
                  onChange={(props) => {
                    if (props.dirty && Object.keys(dirtyFields).length > 0) {
                      setIsDirty(true)
                    }
                  }}
                />
              </Card>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ProspectTerms
