import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import LoanCommitteeReportHeader from '../../components/LoanCommitteeReportHeader'
import CompanySummary from './Components/LoanCommitteeReportOverviewCompanySummary'
import DealStructure from './Components/LoanCommitteeReportOverviewDealStructure'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import BBCAvailabilityTable from '../../components/BBCAvailabilityTable/BBCAvailabilityTable'
import Card from '../../components/Common/Card'
import { ROUTES } from '../../constants/routes'
import { generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import styles from './LoanCommitteeReportOverviewPage.module.scss'
import Empty from '../../components/Common/Empty/Empty'
import { formatDate } from '../../helpers/helpers'
import LCRCommentary from '../../components/LCRCommentary'
import { LCRCommentarySections } from '@common/constants/lcrCommentary'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  isLoading: boolean
}

const LoanCommitteeReportOverviewPage = ({ dueDiligenceInfo, isLoading }: IProps) => {
  const editLink = useMemo(
    () =>
      dueDiligenceInfo?.latestBBC?.id
        ? generatePath(ROUTES.BBC_SUMMARY, { id: dueDiligenceInfo.latestBBC.id })
        : null,
    [dueDiligenceInfo],
  )
  return (
    <Box py={1} pr={2}>
      <LoanCommitteeReportHeader />
      <CompanySummary dueDiligenceInfo={dueDiligenceInfo} isLoading={isLoading} />
      <LCRCommentary section={LCRCommentarySections.CompanyBackground} />
      <DealStructure dueDiligenceInfo={dueDiligenceInfo} isLoading={isLoading} />
      <Card
        className={styles.borrowingBaseCard}
        title={
          <>
            <h2>
              Borrowing Base Summary
              {editLink && (
                <LinkButton component={RouterLink} to={editLink} className={styles.editLink}>
                  Edit
                </LinkButton>
              )}
            </h2>
            {dueDiligenceInfo?.latestBBC && (
              <div className={styles.subTitle}>
                as of {formatDate(dueDiligenceInfo?.latestBBC?.recordDate)}
              </div>
            )}
          </>
        }
      >
        {dueDiligenceInfo?.latestBBC ? (
          <BBCAvailabilityTable bbc={dueDiligenceInfo?.latestBBC} isLoading={isLoading} />
        ) : (
          !isLoading && <Empty height="30vh" message="No borrowing base has been created" />
        )}
      </Card>

      <LCRCommentary section={LCRCommentarySections.TransactionOverview} />
      <LCRCommentary section={LCRCommentarySections.MiscelleanousInfo} />
      <LCRCommentary section={LCRCommentarySections.LegalConsiderations} />
      <LCRCommentary section={LCRCommentarySections.ItemsToMonitor} />
      <LCRCommentary section={LCRCommentarySections.HistoricalLineTurn} />
    </Box>
  )
}

export default LoanCommitteeReportOverviewPage
