import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import cn from 'classnames'
import styles from './ProspectReportingPayablesPage.module.scss'
import { FormSpy } from 'react-final-form'
import { OngoingReportingType } from '@common/interfaces/bbc'
import {
  IOPSReporting,
  IProspectAPSummary,
  OPSReportingFlowStatus,
  OPSReportingStatus,
} from '@common/interfaces/prospects'
import SummaryTable from '../../components/ProspectArApSummary'
import { dateToString } from '../../helpers/helpers'
import KeyboardDatePicker from '../../components/Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../constants/common'
import { formatDate } from '../../helpers/helpers'
import Modal from '../../components/Common/Modal'
import ClientAliasMapping from '../../components/ClientAliasMapping'
import ProspectDashboardHeader from '../../components/ProspectDashboardHeader'
import ProspectFileSelect from '../../components/ProspectFileSelect'
import { EditMapping, ExternalLink, EditSourceData } from '../../components/Common/Icons'
import { ILoadingData } from '../../redux/types'
import ProspectReportingLoader from '../../components/ProspectReportingLoader'
import { ClientInfoStatus } from '@common/interfaces/client'
import OPSWaivedDocument from '../../components/OPSWaivedDocument'
import { usePermissions } from '../../helpers/permissionContext'

interface IProps {
  reporting: IOPSReporting
  show: (id: string) => void
  startReporting: (id: string, data?: object) => void
  apSummaryData: ILoadingData<IProspectAPSummary>
  listAPSummary: (id: string, params: object) => void
  updateDate: (data: object) => Promise<any>
  date: { AP: string; AR: string }
  getDate: ({ fileId }: { fileId: string }) => void
}

const ProspectReportingPayablesPage = ({
  reporting,
  show,
  startReporting,
  apSummaryData,
  listAPSummary,
  updateDate,
  date,
  getDate,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()

  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  const isDataUnmapped = useMemo(
    () => apSummaryData?.data?.data?.filter((item) => item.creditor === null).length > 0,
    [apSummaryData],
  )

  const [refreshCounter, setRefreshCounter] = useState(0)
  const [isFilesSaving, setIsFilesSaving] = useState(false)
  const [isMappingModalShown, setIsMappingModalShown] = useState(isDataUnmapped)
  const togglesMappingModalShown = useCallback(() => setIsMappingModalShown((prev) => !prev), [])

  const opsReportingFlow = useMemo(
    () => reporting?.opsReportingFlows?.filter(({ type }) => type === OngoingReportingType.AP)[0],
    [reporting],
  )
  const isActionRequired = useMemo(() => {
    if (readOnly) {
      return false
    }
    const opsReportingFlow = reporting?.opsReportingFlows?.filter(
      ({ type }) => type === OngoingReportingType.AP,
    )[0]
    return ![OPSReportingFlowStatus.Complete, OPSReportingFlowStatus.Waived].includes(
      opsReportingFlow?.status,
    )
  }, [reporting, readOnly])
  const isFileRequired = useMemo(() => {
    const opsReportingFlow = reporting?.opsReportingFlows?.filter(
      ({ type }) => type === OngoingReportingType.AP,
    )[0]
    return !opsReportingFlow?.files?.[0]?.fileId
  }, [reporting])

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)

  const handleCloseModal = useCallback(async () => {
    setRefreshCounter(refreshCounter + 1)
    setIsMappingModalShown(false)
    show(id)
  }, [setIsMappingModalShown, refreshCounter, setRefreshCounter, show, id])

  const fileIdToUse = useMemo(() => opsReportingFlow?.files?.[0]?.fileId, [opsReportingFlow])

  useEffect(() => {
    if (reporting?.id && !readOnly) {
      startReporting(reporting.id)
    }
  }, [reporting?.id, readOnly, startReporting])

  useEffect(() => {
    if (fileIdToUse) {
      getDate({ fileId: fileIdToUse })
    }
  }, [fileIdToUse, getDate])

  const handleChangeDate = useCallback(
    async (date: any) => {
      const currentAsOfDate = dateToString(date.date)
      const updatedDates = { fileId: fileIdToUse, recordDate: currentAsOfDate }
      if (currentAsOfDate && fileIdToUse) {
        await updateDate(updatedDates)
        setRefreshCounter((counter) => counter + 1)
      }
    },
    [updateDate, setRefreshCounter, fileIdToUse],
  )

  const initialValueDate = useMemo(
    () => ({
      date: date?.AP,
    }),
    [date],
  )
  const selectedFile = useMemo(
    () => reporting?.files.filter(({ id }) => id === fileIdToUse)[0],
    [fileIdToUse, reporting],
  )

  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(() => {
    setIsFileSelectShown(false)
    show(id)
  }, [setIsFileSelectShown, show, id])

  const isWaived = useMemo(
    () => opsReportingFlow?.status === OPSReportingFlowStatus.Waived,
    [opsReportingFlow],
  )

  return (
    <Box py={1} pr={2} className={styles.container}>
      <ProspectDashboardHeader setRefreshCounter={setRefreshCounter} />
      {!readOnly && opsReportingFlow && (
        <ProspectFileSelect
          isOpen={isFileSelectShown}
          reportType={OngoingReportingType.AP}
          opsReportingFlow={opsReportingFlow}
          setRefreshCounter={setRefreshCounter}
          setIsFilesSaving={setIsFilesSaving}
          handleOpenModal={handleOpenSelectFile}
          handleCloseModal={handleCloseSelectFile}
        />
      )}
      <Grid item xs={12}>
        {opsReportingFlow ? (
          isWaived ? (
            <OPSWaivedDocument
              reportType={OngoingReportingType.AP}
              openFileSelectModal={handleOpenSelectFile}
            />
          ) : (
            <SummaryTable
              reportType="AP"
              listSummary={listAPSummary}
              summaryData={apSummaryData}
              refreshCounter={refreshCounter}
              tableClassName={styles.arSummaryTable}
              title={
                <Grid
                  container
                  columnSpacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  mr={2}
                >
                  <Grid item>
                    <Form
                      onSubmit={handleChangeDate}
                      initialValues={initialValueDate}
                      render={({
                        values,
                        handleSubmit,
                      }: {
                        form: any
                        values: any
                        handleSubmit: (e: any) => void
                      }) => (
                        <div className={cn(styles.isDateFilter, styles.tableCellNumber)}>
                          <div className={styles.dateTimeFilter}>
                            <KeyboardDatePicker
                              name={`date`}
                              placeholder="As of Date"
                              inputFormat={DATE_FORMAT}
                              value={formatDate(values.date)}
                              onChange={handleSubmit}
                              disabled={readOnly}
                              withTopLabel
                            />
                            <FormSpy
                              subscription={{ dirty: true, values: true }}
                              onChange={(props) => {
                                props.dirty && handleSubmit(props.values)
                              }}
                            />
                          </div>
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    {selectedFile?.link && (
                      <ExternalLink link={selectedFile.link} title={'AP aging'} />
                    )}
                  </Grid>
                  {!readOnly && (
                    <Grid item>
                      <EditSourceData action={handleOpenSelectFile} error={!!selectedFile?.error} />
                    </Grid>
                  )}
                  <Grid item>
                    {!isUW && (
                      <EditMapping
                        mappingRequired={isActionRequired}
                        mappingDisabled={isFileRequired}
                        action={togglesMappingModalShown}
                      />
                    )}
                  </Grid>
                </Grid>
              }
              isFilesSaving={isFilesSaving}
            />
          )
        ) : (
          <ProspectReportingLoader reportType="AP" />
        )}
      </Grid>
      {isMappingModalShown && (
        <div className={styles.modal}>
          <Modal
            classes={{
              root: styles.modal,
            }}
            open
            onCancel={handleCloseModal}
            toolbar
            disableEnforceFocus
            isTable={true}
            title="Mapping"
          >
            <ClientAliasMapping
              fileId={fileIdToUse}
              table="prospect_ap"
              clientName={reporting?.clientName}
              isProspect
              tableClassName={styles.aliasesTable}
              readOnly={readOnly}
            />
          </Modal>
        </div>
      )}
    </Box>
  )
}

export default ProspectReportingPayablesPage
