import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useParams, useHistory, useLocation } from 'react-router'
import { generatePath } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import styles from './BankTransactionsPage.module.scss'

import FullscreenModal from '../../components/Common/FullscreenModal'
import { formatDate, formatPrice } from '../../helpers/helpers'
import Card from '../../components/Common/Card'
import { IOngoingReporting, OngoingReportingStatus, ReportingFlow } from '@common/interfaces/bbc'
import Button from '../../components/Common/Button'
import { ROUTES } from '../../constants/routes'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { IBankBalance } from '@common/interfaces/bankAccount'
import { BANK_TRANSACTIONS_TABS } from '@common/interfaces/bankTransactions'
import BankTransactionsMapping from '../../components/BankTransactionsMapping'
import moment from 'moment'
import { historyChangeEventHandler, visibilityChangeEventHandler } from '../../helpers/helpers'
import Tabs from '../../components/Common/Tabs'
import BankTransactionsMappingLegend from '../../components/BankTransactionsMappingLegend'
import { EditMapping } from '../../components/Common/Icons'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import BankTransactions from '../../components/BankTransactions'
import { WorkflowTypes } from '@common/interfaces/notes'

const TABS = [BANK_TRANSACTIONS_TABS.DEBITS, BANK_TRANSACTIONS_TABS.CREDITS]

interface IProps {
  listBankTransactions: (params: {
    ongoingReportingId?: string
    page?: number
    filters?: object
  }) => void
  updateOngoingReportingStatus: (data: object) => void
  showOngoingReporting: (id: string) => Promise<void>
  ongoingReporting: IOngoingReporting
  listBankAccountBalances?: (ongoingReportingId: string) => void
  balances?: IBankBalance[]
  listBankTransactionDetail: (data: object) => Promise<any>
  trackActivity: (data: object, beacon?: boolean) => void
  calculateBankTransactionFlags: (id: string) => void
}

const BankTransactionsPage = ({
  updateOngoingReportingStatus,
  showOngoingReporting,
  ongoingReporting,
  listBankAccountBalances,
  balances,
  trackActivity,
  calculateBankTransactionFlags,
}: IProps) => {
  const { id: ongoingReportingId } = useParams<{ id?: string }>()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [refreshCounter, setRefreshCounter] = useState(0)

  const [tabValue, setTabValue] = useState<string>(BANK_TRANSACTIONS_TABS.DEBITS)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startTime, setStartTime] = useState(moment().toISOString())
  const { pathname } = useLocation()

  const isDisabled = useMemo(
    () =>
      [OngoingReportingStatus.Verified, OngoingReportingStatus.Archived].includes(
        ongoingReporting?.status,
      ),
    [ongoingReporting],
  )
  useSetPageTitle(`${ongoingReporting?.clientName} Bank Monitoring ` || '')
  useEffect(() => {
    if (id) {
      showOngoingReporting(id)
      listBankAccountBalances(id)
    }
  }, [id, showOngoingReporting, listBankAccountBalances])

  const logActivity = useCallback(
    (beacon: boolean = false) => {
      if (id && !isDisabled) {
        const endTime = moment().toISOString()
        const data = {
          ongoingReportingId: id,
          startedAt: startTime,
          finishedAt: endTime,
          workflow: REPORTING_DOCUMENT_TYPES.bankTransactions,
          step: REPORTING_DOCUMENT_TYPES.bankTransactions,
        }
        trackActivity(data, beacon)
        setStartTime(endTime)
      }
    },
    [id, startTime, trackActivity, isDisabled],
  )
  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const handleToggleModal = useCallback(() => {
    setIsModalOpen((isOpen) => !isOpen)
  }, [])

  const handleSubmitTransactions = useCallback(() => {
    logActivity()
    updateOngoingReportingStatus({
      id: ongoingReportingId,
      status: OngoingReportingStatus.Verified,
    })
    history.push(ROUTES.ANALYSIS_QUEUE)
  }, [updateOngoingReportingStatus, ongoingReportingId, history, logActivity])

  const currentClientName = useMemo(() => {
    if (ongoingReporting?.id === id) {
      return ongoingReporting?.clientName
    }
  }, [ongoingReporting, id])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ANALYSIS_QUEUE,
        title: 'Analysis queue',
      },
      {
        title: currentClientName,
        link: ongoingReporting?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: ongoingReporting?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES.ONGOING_REPORTING_BANK_TRANSACTIONS_UPLOAD, { id }),
        title: `Bank monitoring`,
      },
    ]
  }, [id, currentClientName, ongoingReporting?.clientInfo?.id])

  const handleCloseMappingModal = useCallback(() => {
    setRefreshCounter((refreshCounter) => refreshCounter + 1)
    !isDisabled && calculateBankTransactionFlags(id)
    setIsModalOpen(false)
  }, [calculateBankTransactionFlags, id, isDisabled])

  return (
    <Box py={1} pr={2}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <Card
        withBorder={false}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <div className={styles.dashboardBlock}>
              <div className={styles.dashboardBlockTitle}>Bank Account Balances</div>
              {balances &&
                balances.map((balance) => (
                  <div className={styles.dashboardBlockContent} key={balance?.id}>
                    <div className={styles.dashboardBlockContentLabel}>
                      {balance.bankAccountInfo.bankName}-
                      {balance.bankAccountInfo.bankAccountNumber.slice(-4)} as of{' '}
                      {formatDate(balance.recordDate)}:
                    </div>
                    <div className={styles.dashboardBlockContentValue}>
                      ${formatPrice(balance.balance)}
                    </div>
                  </div>
                ))}
            </div>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <EditMapping action={handleToggleModal} title="Mapping" />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" ml={2}>
                {isDisabled ? (
                  <Button
                    className={styles.button}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="small"
                    href={ROUTES.ANALYSIS_QUEUE}
                  >
                    Back to Analysis queue
                  </Button>
                ) : (
                  <Button
                    className={styles.button}
                    type="button"
                    onClick={handleSubmitTransactions}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container className={styles.entityInfoGrid}>
              <Grid item xs={6} lg={4}></Grid>
            </Grid>
            <br />

            {isModalOpen && (
              <FullscreenModal
                isOpen
                setIsOpen={handleCloseMappingModal}
                disableEnforceFocus
                showCloseIcon
                classes={{
                  body: styles.fullScreenModalBody,
                }}
              >
                <Card
                  withBorder={false}
                  classes={{
                    title: styles.mappingContainerTitle,
                    content: styles.mappingContainerContent,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                    mr={5}
                  >
                    <Tabs tabs={TABS} selected={tabValue} handleChange={setTabValue} />
                    <Box my={2}>
                      <BankTransactionsMappingLegend />
                    </Box>
                  </Box>

                  <BankTransactionsMapping
                    id={id}
                    currentClientName={currentClientName}
                    transactionType={
                      tabValue === BANK_TRANSACTIONS_TABS.DEBITS ? 'Debit' : 'Credit'
                    }
                    isModalShown={true}
                    reportingFlow={ReportingFlow.OngoingReporting}
                    tableClassName={styles.mappingTable}
                  />
                </Card>
              </FullscreenModal>
            )}

            <Grid item xs={12}>
              <BankTransactions
                workflow={WorkflowTypes.ongoingReporting}
                refreshCounter={refreshCounter}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default BankTransactionsPage
