import React, { ChangeEvent, useEffect, useCallback, useMemo } from 'react'
import cn from 'classnames'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'

import genericSs from '@styles/generic.module.scss'
import styles from './TypeMapping.module.scss'

import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableContainer from '../Common/TableContainer'
import Card from '../Common/Card'
import TableBody from '../Common/TableBody'
import { IClientGeneralLedgerMapping } from '@common/interfaces/clientGeneralLedger'
import AutocompleteField from '../Common/AutocompleteField'
import { ILoadingData } from '../../redux/types'
import SaveState from '../Common/SaveState'
import TableLoader from '../Common/TableLoader'
import { ReportingFlow } from '@common/interfaces/bbc'

const mutators = {
  ...arrayMutators,
  setFieldData,
}

interface IProps {
  typeOptions: Array<{ name: string }>
  typeMappingData: ILoadingData<{ data: IClientGeneralLedgerMapping[] }>
  listTypes: () => void
  updateTypeMapping: (id: string, data: object) => Promise<any>
  listTypeMapping?: (id: string, data?: object) => void
  id: string
  tableClassName?: string
  reportingFlow: ReportingFlow
  readOnly?: boolean
}

const TypeMapping = ({
  typeOptions,
  typeMappingData,
  listTypes,
  updateTypeMapping,
  listTypeMapping,
  id,
  tableClassName,
  reportingFlow,
  readOnly = false,
}: IProps) => {
  const { isLoading, isSaving, isSaved, typeMapping } = useMemo(
    () => ({
      isLoading: typeMappingData.isLoading,
      isSaving: typeMappingData.isSaving,
      isSaved: typeMappingData.isSaved,
      typeMapping: typeMappingData?.data?.data || [],
    }),
    [typeMappingData],
  )
  const handleListTypeMapping = useCallback(
    (skipLoader: boolean = false) => {
      if (id) {
        listTypeMapping(id, { skipLoader, reportingFlow })
      }
    },
    [id, listTypeMapping, reportingFlow],
  )
  useEffect(() => {
    listTypes()
  }, [listTypes])

  useEffect(() => {
    if (id) {
      listTypeMapping(id, { reportingFlow })
    }
  }, [listTypeMapping, id, reportingFlow])

  const handleUpdateTypeMapping = useCallback(
    async (data: { type: string; glMap: string }) => {
      await updateTypeMapping(id, { ...data, reportingFlow })
      handleListTypeMapping(true)
    },
    [updateTypeMapping, handleListTypeMapping, id, reportingFlow],
  )

  const mappingOptions = useMemo(
    () =>
      typeOptions.map((type) => ({
        value: type.name,
        label: type.name,
      })),
    [typeOptions],
  )

  const initialValues = useMemo(
    () => ({
      typeMappings: typeMapping?.map((mapping) => ({
        type: mapping.type,
        glMap: mapping.glMap ? mapping.glMap : '',
      })),
    }),
    [typeMapping],
  )

  return (
    <Grid item xs={12}>
      <Card withBorder={false}>
        <Form
          onSubmit={(e) => e}
          initialValues={initialValues}
          mutators={mutators}
          render={() => (
            <TableContainer className={cn(styles.table, tableClassName)}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Mapping</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id="scrollableTable">
                  {isLoading ? (
                    <TableLoader columnsCount={11} height={26} />
                  ) : (
                    typeMapping && (
                      <FieldArray name="typeMappings">
                        {({ fields }) => (
                          <>
                            {fields.map((name, index) => {
                              const mapping = typeMapping[index]
                              if (!mapping) {
                                return null
                              }
                              return (
                                <TableRow
                                  id={`mapping-table-row-${index}`}
                                  key={mapping.type}
                                  data-index={index}
                                >
                                  <TableCell className={genericSs.tableTextLeft}>
                                    <Tooltip
                                      title={mapping.type}
                                      placement="top"
                                      disableTouchListener
                                    >
                                      <span>{mapping.type}</span>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextLeft}>
                                    <AutocompleteField
                                      inTable
                                      placeholder="Mapping"
                                      tabIndex={4 * index}
                                      name="ineligibleCategory"
                                      options={mappingOptions}
                                      disabled={readOnly}
                                      disableClearable
                                      // @ts-ignore
                                      onChange={(
                                        event: ChangeEvent<{}>,
                                        value: { value: string; label: string },
                                      ) =>
                                        value &&
                                        handleUpdateTypeMapping({
                                          type: mapping?.type,
                                          glMap: value?.value,
                                        })
                                      }
                                      value={
                                        mapping.glMap
                                          ? {
                                              value: mapping?.glMap,
                                              label: mapping?.glMap,
                                            }
                                          : null
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </>
                        )}
                      </FieldArray>
                    )
                  )}
                </TableBody>
              </Table>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </Box>
            </TableContainer>
          )}
        />
      </Card>
    </Grid>
  )
}

export default TypeMapping
