import React, { useEffect, useMemo } from 'react'
import Grid from '@mui/material/Grid'

import styles from './ProspectSummaryPage.module.scss'

import { ReactComponent as WarningIcon } from '@assets/images/warning-icon-outline.svg'
import {
  IOPSReporting,
  OPSReportingFlowStatus,
  OPSReportingStatus,
} from '@common/interfaces/prospects'
import { FinancialsType, OngoingReportingType } from '@common/interfaces/bbc'
import ProspectReportingSummaryFinancials from '../../components/ProspectReportingSummaryFinancials'
import ProspectReportingSummaryReceivables from '../../components/ProspectReportingSummaryReceivables'
import ProspectReportingSummaryPayables from '../../components/ProspectReportingSummaryPayables'
import ProspectReportingSummaryInventory from '../../components/ProspectReportingSummaryInventory'
import ProspectReportingSummaryCashFlow from '../../components/ProspectReportingSummaryCashFlow'
import ProspectReportingSummaryTermStructure from '../../components/ProspectReportingSummaryTermStructure'
import ProspectHeader from '../../components/ProspectHeader'
import { ROUTES } from '../../constants/routes'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { ClientInfoStatus } from '@common/interfaces/client'
import { usePermissions } from '../../helpers/permissionContext'

const ONGOING_REPORTING_TYPE_SUMMARY_ITEM = {
  [OngoingReportingType.AR]: {
    label: 'Receivables',
    path: ROUTES.PROSPECT_REPORTING_RECEIVABLES,
  },
  [OngoingReportingType.AP]: {
    label: 'Payables',
    path: ROUTES.PROSPECT_REPORTING_PAYABLES,
  },
  [OngoingReportingType.Inventory]: {
    label: 'Inventory',
    path: ROUTES.PROSPECT_REPORTING_INVENTORY,
  },
  [OngoingReportingType.IncomeStatement]: {
    label: 'Income statement',
    path: ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT,
  },
  [OngoingReportingType.BalanceSheet]: {
    label: 'Balance sheet',
    path: ROUTES.PROSPECT_REPORTING_BALANCE_SHEET,
  },
  [OngoingReportingType.IncomeStatementProjections]: {
    label: 'Projections income statement',
    path: ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT_PROJECTIONS,
  },
  [OngoingReportingType.BalanceSheetProjections]: {
    label: 'Projections balance sheet',
    path: ROUTES.PROSPECT_REPORTING_BALANCE_SHEET_PROJECTIONS,
  },
}

const ProspectSummaryIncompleteMessage = ({
  types,
}: {
  types: { label: string; path: string }[]
}) => {
  if (!types?.length) {
    return null
  }

  return (
    <Grid item xs={12} className={styles.warningContainer}>
      <WarningIcon />

      <span>
        {types.map(({ label, path }, index) => (
          <>
            <Link component={RouterLink} to={path}>
              {label}
            </Link>
            {index === types.length - 1
              ? ' '
              : types.length > 1 && index === types.length - 2
              ? ' and '
              : ', '}
          </>
        ))}
        {types.length > 1 ? 'are' : 'is'} incomplete
      </span>
    </Grid>
  )
}
interface IProps {
  reporting: IOPSReporting
  show: (id: string) => void
  startReporting: (id: string) => Promise<void>
}

const ProspectSummaryPage = ({ reporting, startReporting }: IProps) => {
  const { id: opsReportingId } = reporting || {}

  const incompleteFlowTypes = useMemo(
    () =>
      (reporting?.opsReportingFlows || [])
        .filter(
          ({ status }) =>
            ![OPSReportingFlowStatus.Complete, OPSReportingFlowStatus.Waived].includes(status),
        )
        .map(({ type }) => type as OngoingReportingType),
    [reporting],
  )

  const isFinancialsIncomplete = useMemo(
    () =>
      [
        OngoingReportingType.IncomeStatement,
        OngoingReportingType.BalanceSheet,
        OngoingReportingType.IncomeStatementProjections,
        OngoingReportingType.BalanceSheetProjections,
      ].every((type) => incompleteFlowTypes.includes(type)),
    [incompleteFlowTypes],
  )

  const isFinancialsActualExists = useMemo(
    () =>
      [OngoingReportingType.IncomeStatement, OngoingReportingType.BalanceSheet].some(
        (type) => !incompleteFlowTypes.includes(type),
      ),
    [incompleteFlowTypes],
  )

  const isFinancialsIncomeStatementExists = useMemo(
    () =>
      [OngoingReportingType.IncomeStatement, OngoingReportingType.IncomeStatementProjections].some(
        (type) => !incompleteFlowTypes.includes(type),
      ),
    [incompleteFlowTypes],
  )

  const isFinancialsBalanceSheetExists = useMemo(
    () =>
      [OngoingReportingType.BalanceSheet, OngoingReportingType.BalanceSheetProjections].some(
        (type) => !incompleteFlowTypes.includes(type),
      ),
    [incompleteFlowTypes],
  )

  const incompleteTypes = useMemo(
    () =>
      incompleteFlowTypes
        .map((type) => ONGOING_REPORTING_TYPE_SUMMARY_ITEM[type])
        .map((item) => ({
          ...item,
          path: generatePath(item.path, { id: opsReportingId }),
        })),
    [incompleteFlowTypes, opsReportingId],
  )

  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  useEffect(() => {
    if (reporting?.id && !readOnly) {
      startReporting(reporting.id)
    }
  }, [reporting?.id, readOnly, startReporting])

  return (
    <Grid
      container
      py={3}
      pr={2}
      rowSpacing={3}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Grid item xs={12}>
        <ProspectHeader />
      </Grid>
      <ProspectSummaryIncompleteMessage types={incompleteTypes} />
      {reporting?.id && !isFinancialsIncomplete && (
        <Grid item xs={12}>
          <ProspectReportingSummaryFinancials
            isIncomeStatementShown={isFinancialsIncomeStatementExists}
            isBalanceSheetShown={isFinancialsBalanceSheetExists}
            defaultFinancialsType={
              isFinancialsActualExists ? FinancialsType.Actuals : FinancialsType.Projections
            }
          />
        </Grid>
      )}
      {!incompleteFlowTypes.includes(OngoingReportingType.AR) && (
        <Grid item xs={12}>
          <ProspectReportingSummaryReceivables />
        </Grid>
      )}
      {!incompleteFlowTypes.includes(OngoingReportingType.AP) && (
        <Grid item xs={12}>
          <ProspectReportingSummaryPayables />
        </Grid>
      )}
        <Grid pl={4} pr={5} item container xs={12} columnSpacing={3}>
          {!incompleteFlowTypes.includes(OngoingReportingType.Inventory) && (
            <Grid item xs={6}>
              <ProspectReportingSummaryInventory />
            </Grid>
          )}
          {!isFinancialsIncomplete && (
            <Grid item xs={6}>
              <ProspectReportingSummaryCashFlow />
            </Grid>
          )}
        </Grid>
      <Grid item xs={12}>
        <ProspectReportingSummaryTermStructure />
      </Grid>
    </Grid>
  )
}

export default ProspectSummaryPage
